import React from 'react'
import Hero from '../components/Hero'
import Info from '../components/Info'
import Top3 from '../components/Top3'
import LeadershipBoard from '../components/LeadershipBoard'
import Faq from '../components/Faq'
import Footer from '../components/Footer'
import { useNavigate, useSearchParams } from 'react-router-dom'

function Home() {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  React.useEffect(() => {
    var token = searchParams.get("token");
    var refresh = searchParams.get("refresh_token");
    if (token !== null && token !== "null") {
      localStorage.setItem("access_token", token);
    }

    if (refresh !== null && token !== "null") {
      localStorage.setItem("refresh_token", refresh);
    }

    if (token !== null && token !== "null") {
      //console.log("token is not null");
      return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
        <Hero/>
        <Info/>
        <Top3/>
        <LeadershipBoard/>
        <Faq/>
        <Footer/>
    </div>
  )
}

export default Home