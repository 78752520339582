import React, { useState } from 'react'
import Bgcolor from "../assets/Group 272.svg"
import Bgcolora from "../assets/Group 271.svg"
import axios from 'axios';

function Info() {
    const [data, setData] = useState({})
    React.useEffect(() => {

        axios.get(`https://api-hamma-f0bcaabf77ea.herokuapp.com/leaderboard/currentcompetition`).then(res => {
            console.log(res.data)
            setData(res.data)
        })

        // setAcc(res.data.filter(acc => acc.acc.number !== 0));

    }, []);
    return (
        <div id='hiw' className='bg-[#0B0A0A] relative flex flex-col items-center px-[1rem]'>
            <img src={Bgcolor} alt="" className='absolute left-0 bottom-[1rem] h-[43.78425rem] hidden lg:block' />
            <img src={Bgcolora} alt="" className='absolute right-0 bottom-[1rem] h-[43.78425rem] hidden lg:block' />
            <div className='flex flex-col lg:flex-row z-10 mt-[5rem] border border-[#06FD06] justify-between xl:w-[79rem] w-[95%] mx-auto rounded-[1.25rem] backdrop-blur-[50px]' style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
                <div className='py-[3rem] lg:w-[50%]'>
                    <p className='font-plus text-[1.375rem] font-semibold text-white mb-[1.69rem] text-center'>1st Place Trader Prize</p>
                    <p className='font-plus text-[2.5rem] font-extrabold text-[#06FD06] text-center'>N{Number(data.first_prize).toLocaleString()}</p>
                </div>
                {/* <div className='py-[3rem] lg:border-x border-y border-gray-500 lg:px-[8.87rem] px-[1.5rem]'>
                    <p className='font-plus text-[1.375rem] font-semibold text-white text-center mb-[1.69rem]'>2nd Place</p>
                    <p className='font-plus text-[2.5rem] font-extrabold text-[#06FD06] text-center'>N{Number(data.second_prize).toLocaleString()}</p>
                </div> */}
                <div className='lg:border-x border-y border-gray-500 '></div>
                <div className='py-[3rem] lg:w-[50%]'>
                    <p className='font-plus text-[1.375rem] font-semibold text-white mb-[1.69rem] text-center'>1st Place Clan Prize</p>
                    <p className='font-plus text-[2.5rem] font-extrabold text-[#06FD06] text-center'>N{Number(data.first_prize).toLocaleString()}</p>
                </div>
            </div>
            <div className='flex flex-col items-center lg:pt-[6.44rem] pt-[5.22rem] pb-[4.6rem]'>
                <h1 className='lg:w-[42.75rem] text-center font-plus lg:text-[3.125rem] text-[2rem] font-extrabold text-white lg:mb-[5.12rem] mb-[3rem]'>Register & Represent your Clan!</h1>
                <div className='flex flex-col lg:flex-row items-center gap-[3.12rem]'>
                    <div className='xl:w-[33%] w-[100%]'>
                        <div className='w-[100%] h-[19.9375rem] rounded-[1.25rem] backdrop-blur-[50px] flex flex-col items-center text-white font-plus relative' style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
                            <svg className='absolute -top-7' width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_1608_668)" />
                                <path d="M24.7691 33V20.72H21.8691V18.1H27.8691V33H24.7691Z" fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_1608_668" x1="25" y1="0" x2="25" y2="50" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#06FD06" />
                                        <stop offset="1" stop-color="#006A00" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <h1 className='text-[1.375rem] font-bold text-center mb-[24px] mt-[62px]'>Create an account</h1>
                            <p className='text-[#E0E0E0] text-center px-5'>Begin your journey by creating a Hamma account. Simply click on the "Sign Up" button to get started. Join our community of ambitious Traders and unlocks a world of exciting trading adventure!</p>
                        </div>
                    </div>
                    <div className='xl:w-[33%] w-[100%]'>
                        <div className='w-[100%] h-[19.9375rem] rounded-[1.25rem] backdrop-blur-[50px] flex flex-col items-center text-white font-plus relative' style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
                            <svg className='absolute -top-7' width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_1608_669)" />
                                <path d="M19.883 33V30.6L23.883 26.48C24.6696 25.6667 25.263 25.0267 25.663 24.56C26.0763 24.08 26.3563 23.68 26.503 23.36C26.663 23.04 26.743 22.7267 26.743 22.42C26.743 21.8733 26.563 21.4467 26.203 21.14C25.8563 20.82 25.4163 20.66 24.883 20.66C24.3363 20.66 23.8496 20.82 23.423 21.14C22.9963 21.4467 22.683 21.92 22.483 22.56L19.783 21.68C19.943 20.9067 20.2763 20.2333 20.783 19.66C21.2896 19.0867 21.903 18.6467 22.623 18.34C23.343 18.02 24.0963 17.86 24.883 17.86C25.8696 17.86 26.7363 18.0333 27.483 18.38C28.2296 18.7267 28.8096 19.2133 29.223 19.84C29.6363 20.4667 29.843 21.2 29.843 22.04C29.843 22.5733 29.7496 23.1 29.563 23.62C29.3763 24.1267 29.0896 24.6467 28.703 25.18C28.3296 25.7 27.843 26.2667 27.243 26.88L23.823 30.4H29.983V33H19.883Z" fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_1608_669" x1="25" y1="0" x2="25" y2="50" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#06FD06" />
                                        <stop offset="1" stop-color="#006A00" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <h1 className='text-[1.375rem] font-bold text-center mb-[24px] mt-[62px]'>Join the Challenge!</h1>
                            <p className='text-[#E0E0E0] text-center px-5'>Ready to showcase your trading prowess against other Traders? Whether you're a seasoned Trader or just starting out, Hamma trading platform is designed for everyone. Remember to join or create a clan to optimize your trading journey. </p>
                        </div>
                    </div>
                    <div className='xl:w-[33%] w-[100%]'>
                        <div className='w-[100%] h-[19.9375rem] rounded-[1.25rem] backdrop-blur-[50px] flex flex-col items-center text-white font-plus relative' style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
                            <svg className='absolute -top-7' width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_1608_672)" />
                                <path d="M25.217 33.24C24.3903 33.24 23.6036 33.0867 22.857 32.78C22.1103 32.46 21.477 32.0133 20.957 31.44C20.437 30.8667 20.0903 30.1933 19.917 29.42L22.657 28.5C22.8836 29.14 23.217 29.6267 23.657 29.96C24.097 30.28 24.617 30.44 25.217 30.44C25.7103 30.44 26.1303 30.3467 26.477 30.16C26.837 29.9733 27.117 29.7133 27.317 29.38C27.517 29.0333 27.617 28.6333 27.617 28.18C27.617 27.7267 27.517 27.34 27.317 27.02C27.1303 26.6867 26.857 26.4333 26.497 26.26C26.1503 26.0733 25.7236 25.98 25.217 25.98C24.9236 25.98 24.6303 26.0133 24.337 26.08C24.0436 26.1467 23.777 26.24 23.537 26.36L22.817 24.86L26.637 20.7H20.817V18.1H30.117V20.5L26.197 24.96L26.297 23.7C27.1903 23.7 27.9636 23.8867 28.617 24.26C29.2836 24.62 29.797 25.1267 30.157 25.78C30.5303 26.4333 30.717 27.1933 30.717 28.06C30.717 29.0467 30.477 29.9333 29.997 30.72C29.517 31.5067 28.8636 32.1267 28.037 32.58C27.2103 33.02 26.2703 33.24 25.217 33.24Z" fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_1608_672" x1="25" y1="0" x2="25" y2="50" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#06FD06" />
                                        <stop offset="1" stop-color="#006A00" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <h1 className='text-[1.375rem] font-bold text-center mb-[24px] mt-[62px]'>Climb Up the Leaderboard</h1>
                            <p className='text-[#E0E0E0] text-center px-5'>Once you've joined the challenge, the real fun begins. Use your trading skills to climb up the leaderboard. You win N500,000 as the top Trader. In addition, as a member of the top Clan, you get an equal share of the N500,000 top Clan prize!</p>
                        </div>
                    </div>
                </div>
                <a href="/register">
                    <button className='font-plus text-[0.875rem] font-medium text-black w-[7.9375rem] h-[2.9375rem] bg-white mt-[4.88rem]'>
                        Sign up
                    </button>
                </a>
            </div>
        </div>
    )
}

export default Info