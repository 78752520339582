import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileMenu from "./MobileMenu";

function Navbar() {
  const [open, setIsOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.reload();
  };

  const location = useLocation();

  useEffect(() => {
    // Check if the URL contains "?scroll=footer"
    const params = new URLSearchParams(location.search);
    const shouldScrollToFaq = params.get('scroll') === 'faq';
    const shouldScrollToHiw = params.get('scroll') === 'hiw';
    const shouldScrollToLeader = params.get('scroll') === 'leader';

    if (shouldScrollToFaq) {
      // Scroll to the footer element
      const footerElement = document.getElementById('faq');
      if (footerElement) {
        footerElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (shouldScrollToHiw) {
      // Scroll to the footer element
      const footerElement = document.getElementById('hiw');
      if (footerElement) {
        footerElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (shouldScrollToLeader) {
      // Scroll to the footer element
      const footerElement = document.getElementById('leader');
      if (footerElement) {
        footerElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div
      className="text-white flex items-center justify-between lg:px-[6.44rem] px-[1rem] lg:py-[0.81rem] py-[1.31rem] backdrop-blur-[10px]"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}
    >
      <Link to="/">
        <svg
          width="123"
          height="26"
          viewBox="0 0 123 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M113.191 7.9734H114.826V26H113.191V7.9734Z" fill="white" />
          <path
            d="M105.018 0.866661C105.018 0.388017 105.42 0 105.917 0H122.101C122.597 0 123 0.388018 123 0.866663V9.0133C123 9.49195 122.597 9.87996 122.101 9.87996H105.917C105.42 9.87996 105.018 9.49195 105.018 9.0133V0.866661Z"
            fill="#19F256"
          />
          <path
            d="M27.1165 21.1996H27.5315V25.9996H27.1165V21.1996Z"
            fill="white"
          />
          <path
            d="M25.8701 20.7999H28.9133V22.6239H25.8701V20.7999Z"
            fill="#19F256"
          />
          <path
            d="M9.81842 1.03999H15.5908V25.9999H9.81842V16.2759H5.77237V25.9999H0V1.03999H5.77237V10.7639H9.81842V1.03999Z"
            fill="white"
          />
          <path
            d="M29.9463 1.03999L37.391 25.9999H31.4028L27.0331 11.4399L22.6634 25.9999H16.6752L24.1739 1.03999H29.9463Z"
            fill="white"
          />
          <path
            d="M52.8625 1.03999H58.5809V25.9999H52.8086V13.5199L49.7875 20.1239H47.252L44.2309 13.5199V25.9999H38.4586V1.03999H44.2309L48.5467 10.3999L52.8625 1.03999Z"
            fill="white"
          />
          <path
            d="M75.1474 1.03999H80.8658V25.9999H75.0935V13.5199L72.0724 20.1239H69.5369L66.5158 13.5199V25.9999H60.7435V1.03999H66.5158L70.8316 10.3999L75.1474 1.03999Z"
            fill="white"
          />
          <path
            d="M95.2205 1.03999L102.665 25.9999H96.677L92.3073 11.4399L87.9376 25.9999H81.9494L89.4481 1.03999H95.2205Z"
            fill="white"
          />
        </svg>
      </Link>

      {/* <Link to="/">
                <img className='w-[6.25063rem]' src="https://res.cloudinary.com/ddlupbcws/image/upload/v1695814861/White_logo_ft9ja_ibfh2k.png" alt="" />
            </Link> */}
      <div className="lg:flex items-center gap-[1.87rem] hidden">
        <div className="flex font-plus font-medium text-[0.875rem] items-center gap-[1.25rem]">
          <Link to="/?scroll=hiw">
            <p>How it works</p>
          </Link>
          <Link to="/?scroll=leader">
            <p>Leaderboard</p>
          </Link>
          <Link to="/?scroll=faq">
            <p>FAQ</p>
          </Link>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://hamma.notion.site/Wiki-9a5606049202424baffacfb33b8b54b0"
          >
            Guide
          </a>
          <a rel="noreferrer" target="_blank" href="/rules">
            Rules
          </a>
        </div>
        <div className="flex items-center gap-[1.87rem]">
          {!localStorage.getItem("access_token") && (
            <Link to="/login">
              <button className="font-plus font-medium text-[0.875rem] border border-white px-[2.62rem] py-[0.94rem] rounded-[0.3125rem]">
                Sign in
              </button>
            </Link>
          )}
          {!localStorage.getItem("access_token") && (
            <Link to="/register">
              <button className="font-plus font-medium text-black text-[0.875rem] bg-white border border-white px-[2.62rem] py-[0.94rem] rounded-[0.3125rem]">
                Sign up
              </button>
            </Link>
          )}
          {localStorage.getItem("access_token") && (
            <button
              onClick={logout}
              className="font-plus font-medium text-[0.875rem] border border-white px-[2.62rem] py-[0.94rem] rounded-[0.3125rem]"
            >
              Log out
            </button>
          )}
          {localStorage.getItem("access_token") && (
            <Link
              to={`https://leaderboard.gohamma.com/?token=${localStorage.getItem(
                "access_token"
              )}&refresh_token=${localStorage.getItem("refresh_token")}`}
            >
              <button className="font-plus font-medium text-black text-[0.875rem] bg-white border border-white px-[2.62rem] py-[0.94rem] rounded-[0.3125rem]">
                Dashboard
              </button>
            </Link>
          )}
        </div>
      </div>
      <div onClick={() => setIsOpen(!open)} className="lg:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38"
          height="22"
          viewBox="0 0 38 22"
          fill="none"
        >
          <rect width="38" height="4" rx="2" fill="#D9D9D9" />
          <rect y="9" width="38" height="4" rx="2" fill="#D9D9D9" />
          <rect y="18" width="38" height="4" rx="2" fill="#D9D9D9" />
        </svg>
      </div>
      <MobileMenu isOpen={open} setIsOpen={setIsOpen} />
    </div>
  );
}

export default Navbar;
