import React from 'react';
import { PuffLoader } from 'react-spinners';

export default function LoadingBox({size,color ,text}) {
  return (
        <div className="loadingBoxComp" style={{width:size, height:size}}>
                <PuffLoader color={color}  size={size}   />
                {
                        ( text==="")?
                        <span className="LoadingText" hidden>{text}</span>
                        :
                        <span className="LoadingText">{text}</span>
                }
        </div>
  );
}


LoadingBox.defaultProps={
        size:45,
        color: "#D4177D",
}