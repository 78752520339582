import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

function Agreement() {
    return (
        <div className='bg-black'>
            <Navbar />
            <div>
                <h1 className='text-white text-[4.6875rem] text-center font-bold my-[80px]'>User Agreement</h1>
                <div className='text-white font-plus px-[274px] flex flex-col gap-16 mb-[40px]'>
                    <div>
                        <h1 className='mb-5'>Lorem ipsum dolor sit amet consectetur.</h1>
                        <p className='text-[#a0a0a0]'>Lorem ipsum dolor sit amet consectetur. Vitae rutrum mi at cras. Varius risus venenatis egestas neque lectus eget ullamcorper proin. Sem eleifend sit consequat aliquet vel nullam malesuada nibh cursus. Sed euismod cursus mattis et est lectus. Risus id amet urna posuere commodo ipsum habitasse a felis. Cursus viverra orci viverra velit et eu blandit. Risus urna vel tempus mi lacus. At elementum ultricies in et rhoncus tortor. Amet id sodales duis arcu porttitor interdum vel urna. Cursus turpis praesent et mauris aliquam enim in condimentum. Et rutrum amet.</p>
                    </div>
                    <div>
                        <h1 className='mb-5'>Lorem ipsum dolor sit amet consectetur.</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>Lorem ipsum dolor sit amet consectetur. Vitae rutrum mi at cras. Varius risus venenatis egestas neque lectus eget ullamcorper proin. Sem eleifend sit consequat aliquet vel nullam malesuada nibh cursus. Sed euismod cursus mattis et est lectus. Risus id amet urna posuere commodo ipsum habitasse a felis. Cursus viverra orci viverra velit et eu blandit. Risus urna vel tempus mi lacus. At elementum ultricies in et rhoncus tortor. Amet id sodales duis arcu porttitor interdum vel urna. Cursus turpis praesent et mauris aliquam enim in condimentum. Et rutrum amet.</li>
                            <li>Lorem ipsum dolor sit amet consectetur. Vitae rutrum mi at cras. Varius risus venenatis egestas neque lectus eget ullamcorper proin. Sem eleifend sit consequat aliquet vel nullam malesuada nibh cursus. Sed euismod cursus mattis et est lectus. Risus id amet urna posuere commodo ipsum habitasse a felis. Cursus viverra orci viverra velit et eu blandit. Risus urna vel tempus mi lacus. At elementum ultricies in et rhoncus tortor. Amet id sodales duis arcu porttitor interdum vel urna. Cursus turpis praesent et mauris aliquam enim in condimentum. Et rutrum amet.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Lorem ipsum dolor sit amet consectetur.</h1>
                        <p className='text-[#a0a0a0]'>Lorem ipsum dolor sit amet consectetur. Vitae rutrum mi at cras. Varius risus venenatis egestas neque lectus eget ullamcorper proin. Sem eleifend sit consequat aliquet vel nullam malesuada nibh cursus. Sed euismod cursus mattis et est lectus. Risus id amet urna posuere commodo ipsum habitasse a felis. Cursus viverra orci viverra velit et eu blandit. Risus urna vel tempus mi lacus. At elementum ultricies in et rhoncus tortor. Amet id sodales duis arcu porttitor interdum vel urna. Cursus turpis praesent et mauris aliquam enim in condimentum. Et rutrum amet.</p>
                    </div>
                    <div>
                        <h1 className='mb-5'>Lorem ipsum dolor sit amet consectetur.</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>Lorem ipsum dolor sit amet consectetur. Vitae rutrum mi at cras. Varius risus venenatis egestas neque lectus eget ullamcorper proin. Sem eleifend sit consequat aliquet vel nullam malesuada nibh cursus. Sed euismod cursus mattis et est lectus. Risus id amet urna posuere commodo ipsum habitasse a felis. Cursus viverra orci viverra velit et eu blandit. Risus urna vel tempus mi lacus. At elementum ultricies in et rhoncus tortor. Amet id sodales duis arcu porttitor interdum vel urna. Cursus turpis praesent et mauris aliquam enim in condimentum. Et rutrum amet.</li>
                            <li>Lorem ipsum dolor sit amet consectetur. Vitae rutrum mi at cras. Varius risus venenatis egestas neque lectus eget ullamcorper proin. Sem eleifend sit consequat aliquet vel nullam malesuada nibh cursus. Sed euismod cursus mattis et est lectus. Risus id amet urna posuere commodo ipsum habitasse a felis. Cursus viverra orci viverra velit et eu blandit. Risus urna vel tempus mi lacus. At elementum ultricies in et rhoncus tortor. Amet id sodales duis arcu porttitor interdum vel urna. Cursus turpis praesent et mauris aliquam enim in condimentum. Et rutrum amet.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Agreement