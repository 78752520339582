import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function Rules() {
  return (
    <div className="bg-black">
      <Navbar />
      <div>
        <h1 className="text-white lg:text-[4.6875rem] text-[3rem] text-center font-bold my-[80px]">
          Competition Rules
        </h1>
        {/* <p className="text-[#a0a0a0] lg:w-[50%] w-[95%] text-center mx-auto mt-[10px] mb-[80px]">
          Welcome to competition.gohamma.com (the "Website"). These Terms and
          Conditions ("Terms") govern your access to and use of the Website. You
          agree that by accessing the Site, you have read, understood, and
          agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
          WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM
          USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </p> */}
        <div className="text-white font-plus lg:px-[274px] px-[1rem] flex flex-col gap-16 mb-[40px]">
          <div>
            <h1 className="mb-5">For trader to qualify for Top Clan payout:</h1>
            <ul className="list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]">
              <li>Clan must have a minimum of 3 member</li>
              <li>Trader must have traded for a minimum of 10 days.</li>
              <li>Trader must have completed their KYC</li>
            </ul>
          </div>
          <div>
            <h1 className="mb-5">For a trader to qualify for Top Trader payout:</h1>
            <ul className="list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]">
              <li>Trader must have traded for a minimum of 10 days.</li>
              <li>Trader must have completed their KYC</li>
            </ul>
          </div>
        </div>
        <p className="text-[#a0a0a0] lg:w-[50%] w-[95%] text-center mx-auto my-[50px]">
          By partaking in the competiton, you agree to abide by these Rules provided to you in connection
          with payout at the end of the competiton.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Rules;
