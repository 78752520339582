import React from "react";

function Top3TradersTable(props) {
    return (
        <table className="w-[100%] border-collapse">
            <thead className="border-y border-gray-500">
                <tr className="w-[100%]">
                    {props.column.map((row) => (
                        <td
                            className={`text-center py-[1rem] font-plus ${row.key === "total-profit" ? "text-[#00C500]" : "text-white"} text-[0.875rem] font-semibold`}
                            key={row.key}
                        >
                            {row.label}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody className="w-[100%]">
                {props.data.map((item, index) => (
                    <tr key={item.id} className="">
                        <td className="text-center py-[1rem] px-[0.5rem] font-plus text-[0.875rem] font-medium text-white">
                            {index + 1}.
                        </td>
                        <td className="text-center py-[1rem] font-plus text-[0.775rem] font-medium text-white">
                            {item.name}
                        </td>
                        <td className={`text-center py-[1rem] px-[0.5rem] font-plus text-[0.875rem] font-medium ${item.profit < 0 ? "text-red-500" : "text-[#00C500]" }`}>
                            {item.profit}%
                        </td>
                        {/* <td className="text-center py-[1rem] font-plus text-[0.875rem] font-medium text-[#00C500]">
                            {item.hours}
                        </td>
                        <td className="flex justify-center py-[1rem] font-plus text-[0.875rem] font-medium text-white">
                            <img src={item.country} alt="" />
                        </td> */}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default Top3TradersTable;
