/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import { FiSend } from "react-icons/fi";
import { LuThumbsUp, LuThumbsDown } from "react-icons/lu";
import { useForm } from "react-hook-form";
// import Image from 'next/image';
import { MdMessage } from "react-icons/md";
import LoadingBox from "../loadingbox/LoadingBox";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import toast from "react-hot-toast";
import SyncLoading from "../loadingbox/SyncLoader";
// import { useRouter } from 'next/router';
import { BsStars } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import TypingChatbot from "../../assets/typing_chatbot.svg"
import "./supportChat.css"

function extractFirstErrorMessage(errors) {
  const combinedErrors = Object.values(errors).flatMap(
    (errorArray) => errorArray
  );
  if (combinedErrors.length > 0) {
    return combinedErrors[0]; // Return the first error message from the combined array
  }
  return null; // Return null if no errors found
}

const SupportChatbot = () => {
  const chatRef = useRef(null);
  const scrollableRef = useRef(null);
  const {
    register,
    handleSubmit: hookHandleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const thumbsDownRef = useOnclickOutside(() => setModalCount());
  //   const router = useRouter();

  const [show, setshow] = useState(false);
  const [showChart, setshowChart] = useState(true);

  const [socket, setSocket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [botVoteLoading, setBotVoteLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [modalCount, setModalCount] = useState();

  const lastFT9jaMessage = messages
    .filter((msg) => msg.sender !== "user")
    .pop();
  const isChatSwitchedToAdmin =
    lastFT9jaMessage?.message?.chat_switched_to_admin === true;

  const scrollToBottom = (ref) => {
    if (ref.current) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight + 200,
        behavior: "smooth",
      });
    }
  };

  const promptBot = (msg) => {
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      initializeWebSocket();
      return toast.error("Kindly hold on...");
    }

    if (!msg) return;
    if (socket) {
      setLoading(true);
      socket.send(JSON.stringify({ message: msg }));
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: msg, sender: "user", created: new Date() },
      ]);
      setMessage("");

      scrollToBottom(scrollableRef);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      initializeWebSocket();
      return toast.error("Kindly hold on...");
    }

    if (!message) return;
    if (socket) {
      setLoading(!isChatSwitchedToAdmin);
      socket.send(JSON.stringify({ message: message }));
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: message, sender: "user", created: new Date() },
      ]);
      setMessage("");
      scrollToBottom(scrollableRef);
    }
  };

  const initializeWebSocket = () => {
    let email = getValues("email");
    let name = getValues("name");

    if (!email || !name) {
      toast.error("Kindly input your name and email.");
      return;
    }
    const isProduction = "api-hamma-f0bcaabf77ea.herokuapp.com".includes(
      "herokuapp"
    );
    const protocol = isProduction ? "wss" : "ws";
    const socketUrl = `${protocol}://${"api-hamma-f0bcaabf77ea.herokuapp.com".replace(
      /^http(s)?:\/\//,
      ""
    )}/ws/no-auth-support/?name=${name}&email=${email}`;

    const newSocket = new WebSocket(socketUrl);
    setSocket(newSocket);

    newSocket.onopen = () => {
      console.log("WebSocket connection established.");
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("data.message", data.message);

      const formatContent = (content) => {
        // Remove JSON blocks
        content = content.replace(/```json\s*[\s\S]*?\s*```/g, "");

        let formattedContent = content.replace(/```([\s\S]*?)```/g, (match) => {
          return match.replace(/\n/g, "PLACEHOLDER_FOR_NEWLINE");
        });
        formattedContent = formattedContent.replace(/\n/g, "<br>");
        formattedContent = formattedContent.replace(
          /PLACEHOLDER_FOR_NEWLINE/g,
          "\n"
        );

        formattedContent = formattedContent.replace(
          /\*\*(.*?)\*\*/g,
          "<strong>$1</strong>"
        );
        formattedContent = formattedContent.replace(
          /\*(.*?)\*/g,
          "<em>$1</em>"
        );
        formattedContent = formattedContent.replace(
          /`([^`]+)`/g,
          '<code class="block bg-[#0d0d0d] text-white p-3 m-2">$1</code>'
        );
        formattedContent = formattedContent.replace(
          /```([\s\S]*?)```/g,
          "<pre><code>$1</code></pre>"
        );

        formattedContent = formattedContent.replace(
          /###### (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /##### (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /#### (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /### (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /## (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /# (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );

        formattedContent = formattedContent.replace(
          /(\d+)\. (.*?)<br>/g,
          (match, p1, p2) => `<li>${p2}</li>`
        );
        formattedContent = formattedContent.replace(
          /(<li>.*?<\/li>)+/g,
          (match) => `<ol>${match}</ol>`
        );
        formattedContent = formattedContent.replace(
          /^\*\s(.*?)$/gm,
          "<li>$1</li>"
        );
        formattedContent = formattedContent.replace(
          /(<li>.*?<\/li>)+/g,
          (match) => `<ul>${match}</ul>`
        );
        formattedContent = formattedContent.replace(
          /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g,
          '<a class="text-blue-500 underline transition hover:text-blue-300" href="$2" target="_blank">$1</a>'
        );

        return formattedContent;
      };

      if (Array.isArray(data.message)) {
        const newMessages = data.message
          .map((msg) => {
            return {
              ...msg,
              message: {
                ...msg.message,
                response: formatContent(msg.message.content ?? ""),
              },
            };
          })
          .filter(Boolean);
        setMessages((prevMessages) => [...prevMessages, ...newMessages]);
        setLoading(false);

        // updateQueryParams('thread', newMessages[0]?.thread_value ?? "");
      } else {
        const newMessage = {
          ...data,
          message: {
            ...data.message,
            response: formatContent(data.message.content ?? ""),
          },
          sender: "assistant",
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        setLoading(false);
        // updateQueryParams('thread', data?.thread_value ?? "");
      }
    };

    newSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
      console.log("An error occurred. Please try again.");
      setLoading(false);
    };

    newSocket.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
      console.log("WebSocket connection closed. Please refresh the page.");
      setLoading(false);
    };

    return () => {
      if (newSocket) newSocket.close();
    };
  };

  // const clearMessagesAndRestart = () => {
  //         setLoading(false)
  //         setMessages([]);
  //         if (socket) socket.close();
  //         initializeWebSocket();
  // };

  const submitHandler = async ({ email, name }) => {
    if (email && name) {
      // initializeWebSocket();
      setshowChart(false);
    }
  };

  //   useEffect(() => {
  //     if (router.query.chatbot == "true") setshow(true);

  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [router.query.chatbot]);

  useEffect(() => {
    let email = getValues("email");
    let name = getValues("name");

    if (email && name) {
      initializeWebSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("email"), getValues("name")]);

  const voteBotResponse = async (formData) => {
    try {
      setBotVoteLoading(true);

      const { data } = await axios.post(
        `https://api-hamma-f0bcaabf77ea.herokuapp.com/support/bot/rate-response/`,
        formData
      );

      toast.success(`Thanks for the feedback`);
      setBotVoteLoading(false);
    } catch (error) {
      console.log(error);
      setBotVoteLoading(false);
      toast.error(
        error.response && error.response.data
          ? extractFirstErrorMessage(error.response.data)
          : error.message
      );
    }
  };

  const handleReconnecttoAI = async () => {
    try {
      setLoading(true);
      const { data } = await axios.put(
        `https://api-hamma-f0bcaabf77ea.herokuapp.com/support/switch-back-to-ai-bot/${lastFT9jaMessage.message.thread}`
      );
      toast.success(`Reconnecting...`);
    } catch (error) {
      console.log(error);
      toast.error(
        error.response && error.response.data
          ? extractFirstErrorMessage(error.response.data)
          : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=" fixed z-[1000] right-4 md:right-6 bottom-6 flex flex-col justify-end items-end">
      <AnimatePresence initial={false} mode="wait">
        {show && (
          <motion.div
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            transition={{ type: "spring", bounce: 0.3, duration: 0.35 }}
            className={
              "bg-white rounded-md  mb-4 overflow-hidden w-[408px] h-[640px]  min-w-[100px]  min-h-[100px] max-h-[80vh] max-w-[90vw]"
            }
            style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 16px" }}
          >
            <div className="bg-[#282828] w-full p-4 flex justify-between items-center gap-2 ">
              <div className="h-12 w-20 -mb-4">
              <svg width="123" height="26" viewBox="0 0 123 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M113.191 7.9734H114.826V26H113.191V7.9734Z" fill="white" />
                    <path d="M105.018 0.866661C105.018 0.388017 105.42 0 105.917 0H122.101C122.597 0 123 0.388018 123 0.866663V9.0133C123 9.49195 122.597 9.87996 122.101 9.87996H105.917C105.42 9.87996 105.018 9.49195 105.018 9.0133V0.866661Z" fill="#19F256" />
                    <path d="M27.1165 21.1996H27.5315V25.9996H27.1165V21.1996Z" fill="white" />
                    <path d="M25.8701 20.7999H28.9133V22.6239H25.8701V20.7999Z" fill="#19F256" />
                    <path d="M9.81842 1.03999H15.5908V25.9999H9.81842V16.2759H5.77237V25.9999H0V1.03999H5.77237V10.7639H9.81842V1.03999Z" fill="white" />
                    <path d="M29.9463 1.03999L37.391 25.9999H31.4028L27.0331 11.4399L22.6634 25.9999H16.6752L24.1739 1.03999H29.9463Z" fill="white" />
                    <path d="M52.8625 1.03999H58.5809V25.9999H52.8086V13.5199L49.7875 20.1239H47.252L44.2309 13.5199V25.9999H38.4586V1.03999H44.2309L48.5467 10.3999L52.8625 1.03999Z" fill="white" />
                    <path d="M75.1474 1.03999H80.8658V25.9999H75.0935V13.5199L72.0724 20.1239H69.5369L66.5158 13.5199V25.9999H60.7435V1.03999H66.5158L70.8316 10.3999L75.1474 1.03999Z" fill="white" />
                    <path d="M95.2205 1.03999L102.665 25.9999H96.677L92.3073 11.4399L87.9376 25.9999H81.9494L89.4481 1.03999H95.2205Z" fill="white" />
                </svg>
              </div>

              <Tippy
                content={"reconnect to AI chatbot"}
                placement="top" // You can change the placement as needed
                maxWidth="350px" // Optional: Adjust maxWidth directly
              >
                {isChatSwitchedToAdmin ? (
                  <button
                    className="rounded-full w-[32px] h-[32px] min-w-[32px] min-h-[32px] text-black bg-[#fff] transition flex items-center justify-center  hover:scale-[0.9]  "
                    onClick={() => handleReconnecttoAI()}
                  >
                    <BsStars size={20} />
                  </button>
                ) : (
                  ""
                )}
              </Tippy>
            </div>

            {!showChart ? (
              <>
                <div
                  className="w-full p-2  overflow-y-scroll"
                  style={{ height: "calc( 100% - 64px - 56px - 5px  )" }}
                  ref={scrollableRef}
                >
                  <div className="flex flex-col mb-6 ">
                    <div className="flex gap-4">
                      <span
                        className={`flex justify-center items-center capitalize text-center text-white font-semibold w-6 h-6 min-w-6 min-h-6 rounded-full overflow-hidden  bg-[#00BA77]`}
                      >
                        <ing
                          src={
                            messages.find((val) => val.sender === "assistant")
                              ? messages
                                  .find((val) => val.sender === "assistant")
                                  ?.message?.fullname.includes("George Soros")
                                ? "https://ft9japayoutbucket3.s3.amazonaws.com/media/support_profile_images/male_profile.jpg"
                                : " https://ft9japayoutbucket3.s3.amazonaws.com/media/support_profile_images/female_profile.jpg"
                              : "https://i.pinimg.com/564x/1e/71/2d/1e712d2318258083c0df91f9ec749b77.jpg"
                          }
                          alt="user avater"
                          width={500}
                          height={500}
                          className="object-cover w-full h-full"
                        />
                      </span>

                      <div className="max-w-[80%] w-fit text-[14px] bg-[#f2f2f2] font-medium p-2 rounded-[10px] rounded-tl-none group">
                        <p className="text-[#00BA77] font-bold text-[14px] mb-2">
                          {" "}
                          {messages.find((val) => val.sender === "assistant")
                            ? messages.find((val) => val.sender === "assistant")
                                ?.message?.fullname
                            : "Hamma Chatbot"}
                        </p>
                        <p>Hello! Welcome to Hamma 👋</p>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <span
                        className={`flex justify-center items-center capitalize text-center text-white font-semibold w-6 h-6 min-w-6 min-h-6 rounded-full overflow-hidden  bg-[#00BA77] opacity-0`}
                      >
                        {" "}
                        <img
                          src={
                            "https://i.pinimg.com/564x/1e/71/2d/1e712d2318258083c0df91f9ec749b77.jpg"
                          }
                          alt="user avater"
                          width={500}
                          height={500}
                          className="object-cover w-full h-full"
                        />{" "}
                      </span>
                      <div className="max-w-[80%] w-fit text-[14px] bg-[#f2f2f2] font-medium p-2 rounded-[10px] rounded-tl-none group mt-2">
                        <p className="text-[#00BA77] font-bold text-[14px] mb-2">
                          {" "}
                          {messages.find((val) => val.sender === "assistant")
                            ? messages.find((val) => val.sender === "assistant")
                                ?.message?.fullname
                            : "Hamma Chatbot"}
                        </p>
                        <p>What are you interested in doing today?</p>
                      </div>
                    </div>

                    {messages.length === 0 && (
                      <div className="flex justify-center flex-col gap-1  mb-3 mt-2 ml-10">
                        <button
                          className="block w-fit text-[12px] text-offBlack border border-solid border-[#00BA77] rounded-[5px] p-1.5  transition hover:opacity-60"
                          disabled={loading}
                          onClick={() => promptBot("How does this work")}
                        >
                          How does this work
                        </button>
                        <button
                          className="block w-fit text-[12px] text-offBlack border border-solid border-[#00BA77] rounded-[5px] p-1.5  transition hover:opacity-60"
                          disabled={loading}
                          onClick={() => promptBot("What are the rules?")}
                        >
                          What are the rules?
                        </button>
                        <button
                          className="block w-fit text-[12px] text-offBlack border border-solid border-[#00BA77] rounded-[5px] p-1.5  transition hover:opacity-60"
                          disabled={loading}
                          onClick={() =>
                            promptBot("I'm looking to do something else ")
                          }
                        >
                          I&apos;m looking to do something else
                        </button>
                      </div>
                    )}
                  </div>
                  {messages.map((msg, i) => {
                    return (
                      <div key={i}>
                        {msg.sender !== "user" ? (
                          <div className="flex flex-col mb-6 " key={i}>
                            <div className="flex gap-4">
                              <span
                                className={`flex justify-center items-center capitalize text-center text-white font-semibold w-6 h-6 min-w-6 min-h-6 rounded-full overflow-hidden ${
                                  msg.user_pics
                                    ? "bg-[#D8D8D8]"
                                    : "bg-[#00BA77]"
                                }`}
                              >
                                {msg.user_pics || msg.sender === "assistant" ? (
                                  <img
                                    src={
                                      msg.sender === "assistant"
                                        ? msg?.message?.fullname.includes(
                                            "George Soros"
                                          )
                                          ? "https://ft9japayoutbucket3.s3.amazonaws.com/media/support_profile_images/male_profile.jpg"
                                          : " https://ft9japayoutbucket3.s3.amazonaws.com/media/support_profile_images/female_profile.jpg"
                                        : msg.user_pics
                                    }
                                    width={500}
                                    height={500}
                                    alt="user avater"
                                    className="object-cover w-full h-full"
                                  />
                                ) : (
                                  msg.user_name?.split(" ")[0][0]
                                )}
                              </span>

                              <div className="max-w-[80%] w-fit text-[14px] bg-[#f2f2f2] font-medium p-2 rounded-[10px] rounded-tl-none group">
                                <p className="text-[#00BA77] font-bold text-[14px] mb-2">
                                  {msg.sender === "assistant"
                                    ? msg?.message?.fullname ?? "Hamma Chatbot"
                                    : msg.user_name?.split(" ")[0] + "(Admin)"}
                                </p>

                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: msg.message.response ?? "",
                                  }}
                                ></p>

                                {msg.sender === "assistant" &&
                                  !msg.message?.chat_switched_to_admin && (
                                    <>
                                      <p
                                        className={`text-[10px] ${
                                          messages.length - 1 === i
                                            ? "block"
                                            : "hidden"
                                        }  opacity-60 mt-2.5  group-hover:block transition`}
                                      >
                                        Generated by AI. Double check for
                                        accuracy
                                      </p>
                                      <span
                                        className={`mt-1 ${
                                          messages.length - 1 === i
                                            ? "flex"
                                            : "hidden"
                                        } items-center justify-center gap-1 w-fit  group-hover:flex transition `}
                                      >
                                        <motion.button
                                          className="flex justify-center items-center border border-stone-300 border-solid rounded p-1 text-[12px] transition hover:bg-stone-400 hover:text-white"
                                          disabled={botVoteLoading}
                                          whileTap={{ scale: 1.1 }}
                                          onClick={() =>
                                            voteBotResponse({
                                              message: msg.message.id,
                                              vote: "upvote",
                                              thread: msg.message.thread,
                                              email: getValues("email"),
                                              name: getValues("name"),
                                            })
                                          }
                                        >
                                          {botVoteLoading ? (
                                            <LoadingBox
                                              size={10}
                                              color={"#151515"}
                                            />
                                          ) : (
                                            <LuThumbsUp />
                                          )}
                                        </motion.button>
                                        <motion.button
                                          className="flex justify-center items-center border border-stone-300 border-solid rounded p-1 text-[12px] transition hover:bg-stone-400 hover:text-white"
                                          disabled={botVoteLoading}
                                          whileTap={{ scale: 1.1 }}
                                          onClick={() => setModalCount(i)}
                                        >
                                          {botVoteLoading ? (
                                            <LoadingBox
                                              size={10}
                                              color={"#151515"}
                                            />
                                          ) : (
                                            <LuThumbsDown />
                                          )}
                                        </motion.button>

                                        <AnimatePresence initial={false} mode="wait">
                                          {modalCount === i && (
                                            <motion.div
                                              className="p-1.5 lg:p-3 rounded border border-solid border-[#F2F2F2] text-[10px] mt-2"
                                              initial={{ opacity: "0" }}
                                              animate={{ opacity: 1 }}
                                              exit={{ opacity: "0" }}
                                              transition={{
                                                type: "spring",
                                                bounce: 0.3,
                                                duration: 0.4,
                                              }}
                                              ref={thumbsDownRef}
                                              key="thumbsDownRef"
                                            >
                                              <p className="opacity-60 text-xm mb-2">
                                                Tell us more:
                                              </p>

                                              <div className="flex gap-2.5 flex-wrap">
                                                {[
                                                  "Inaccurate or incomplete",
                                                  " Was off-topic",
                                                  "Difficult to understand",
                                                  "Not useful information",
                                                  "No detail or examples",
                                                  "Broken links or references",
                                                  "Incorrect code",
                                                  "Other",
                                                ].map((feedback, i) => (
                                                  <button
                                                    className="p-1.5 rounded bg-white border border-solid border-[#e8e8e8] transition hover:bg-[#e8e8e8] "
                                                    onClick={() => {
                                                      voteBotResponse({
                                                        message: msg.message.id,
                                                        vote: "downvote",
                                                        thread:
                                                          msg.message.thread,
                                                        feedback,
                                                        email:
                                                          getValues("email"),
                                                        name: getValues("name"),
                                                      });
                                                      setModalCount(null);
                                                    }}
                                                    key={i}
                                                  >
                                                    {feedback}
                                                  </button>
                                                ))}
                                              </div>
                                            </motion.div>
                                          )}
                                        </AnimatePresence>
                                      </span>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col mb-6 items-end" key={i}>
                            <div className="flex flex-row-reverse gap-4">
                              <div className="max-w-[80%] min-w-[35px] w-fit text-[14px] bg-[#00BA774a] font-medium p-2 rounded-[10px] rounded-tr-none">
                                <p className="w-full">{msg.message ?? ""}</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  {loading && !isChatSwitchedToAdmin && (
                    <div className="flex flex-col mb-6 ">
                      <div className="flex gap-4">
                        <div className="!w-fit !h-full !min-w-6 !min-h-6 !max-w-6 !max-h-6">
                          <img
                            className="!w-full !h-full !min-w-6 !min-h-6 !max-w-6 !max-h-6 rounded-full  "
                            src="https://i.pinimg.com/564x/1e/71/2d/1e712d2318258083c0df91f9ec749b77.jpg"
                            alt="chat avater"
                            width={24}
                            height={24}
                            style={{ minHeight: "24px !important" }}
                          />
                        </div>
                        <p className="max-w-[80%] w-fit text-[14px] bg-[#f2f2f2] font-medium p-2 rounded-[10px] rounded-tl-none">
                          <SyncLoading />
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="w-full p-1 pb-0">
                  <form
                    id="message-form"
                    onSubmit={handleSubmit}
                    className=" h-[44px] mt-2   bg-[#f2f2f2] w-full flex justify-center items-center gap-2 rounded-[5px] px-2 py-1  "
                    ref={chatRef}
                  >
                    <input
                      className="flex-1 bg-transparent !outline-none !border-none hover:!outline-0  focus:!ring-transparent !px-2 min-w-[120px] sm:!px-3 "
                      type="text"
                      placeholder="What do you want to discuss?"
                      id="message-input"
                      disabled={loading}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button
                      className={`flex justify-center items-center gap-2 py-[6px] !px-2 sm:!px-3 font-medium text-xm ${
                        message ? "bg-[#00BA77]" : "bg-[#ABABAB]"
                      } rounded-[5px] text-white transition duration-200 hover:opacity-40`}
                      type="submit"
                      disabled={loading}
                    >
                      <FiSend className="w-[20px] h-[20px]" />{" "}
                      <span className=" hidden sm:flex">Send</span>
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <form
                className="p-4 py-8 md:py-4 h-full flex justify-start flex-col  overflow-y-scroll "
                style={{ height: "calc( 100% - 80px )" }}
                onSubmit={hookHandleSubmit(submitHandler)}
              >
                <div className="mb-3  w-fit mx-auto">
                  <img
                    src={TypingChatbot}
                    className=""
                    alt="chatbot avater"
                    width={140}
                    height={140}
                    loading="lazy"
                  />
                </div>

                <p className="mb-4">
                  To send us a message, first leave us your contact information
                  so we can always get back to you.
                </p>

                <div className="formController mb-4 ">
                  <label htmlFor="name" className="font-medium">
                    Full name{" "}
                  </label>
                  <div className={`form_input  `}>
                    <input
                      id="name"
                      className="border-solid border-[#BEBEBE] border-[.5px] focus:!ring-transparent"
                      type="text"
                      disabled={loading}
                      style={{ padding: "11px 12px" }}
                      {...register("name", {
                        required: "Kindly input your name. 😂",
                      })}
                    />
                  </div>
                  {errors.name ? (
                    <span className="desc warn "> {errors.name.message} </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="formController ">
                  <label htmlFor="email" className="font-medium">
                    Email Address{" "}
                  </label>
                  <div className={`form_input  `}>
                    <input
                      id="email"
                      className="border-solid border-[#BEBEBE] border-[.5px]  focus:!ring-transparent"
                      type="email"
                      disabled={loading}
                      style={{ padding: "11px 12px" }}
                      {...register("email", {
                        required: "Kindly input your email. 😂",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "Haha... invalid email address. 😂",
                        },
                      })}
                    />
                  </div>
                  {errors.email ? (
                    <span className="desc warn "> {errors.email.message} </span>
                  ) : (
                    ""
                  )}
                </div>

                <button
                  type="submit"
                  className="!text-white !bg-[#00BA77] footer_btn mt-[30px] md:mt-[60px]  mx-auto  px-4 py-3 rounded-md transition hover:opacity-60 "
                >
                  Submit
                </button>
              </form>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <div
        className="flex items-center justify-center rounded-full h-16 w-16 min-h-16 min-w-16 bg-[#00BA77]  border-2 border-solid border-white cursor-pointer"
        onClick={() => setshow((val) => !val)}
      >
        {show ? (
          <IoClose color="#fff" size={28} />
        ) : (
          <MdMessage color="#fff" size={28} />
        )}
      </div>
    </div>
  );
};

export default SupportChatbot;
