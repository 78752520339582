import React, { useState } from "react";
// import Country from "../assets/Group 461.svg"
import LeadershipBoardTable from "./tables/LeadershipBoardTable";
import axios from "axios";

const headers = [
  {
    key: "id",
    label: "",
  },
  {
    key: "name",
    label: "Name",
  },
  // {
  //   key: "acc_size",
  //   label: "Account Size",
  // },
  // {
  //   key: "model",
  //   label: "Model",
  // },
  {
    key: "total-profit",
    label: "Profit %",
  },
  {
    key: "total-profit-naira",
    label: "Profit",
  },
  {
    key: "clan",
    label: "Clan",
  },
  {
    key: "country",
    label: "Country",
  },
];

function LeadershipBoard() {
  const [data, setData] = useState([]);
  const [dataCurr, setDataCurr] = useState({});
  const [started, setStarted] = useState(false)
  console.log(started)

  function isDateAfterToday(dateString) {
    const inputDate = new Date(dateString);
    const today = new Date();

    // Clear the time part of today's date
    today.setHours(0, 0, 0, 0);

    return inputDate > today;
}

  React.useEffect(() => {
    axios
      .get(
        `https://api-hamma-f0bcaabf77ea.herokuapp.com/leaderboard/leaderboard`
      )
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      });

    axios
      .get(
        `https://api-hamma-f0bcaabf77ea.herokuapp.com/leaderboard/currentcompetition`
      )
      .then((res) => {
        console.log(res.data);
        setDataCurr(res.data);
        setStarted(isDateAfterToday(res.data.start_date))
      });

    // setAcc(res.data.filter(acc => acc.acc.number !== 0));
  }, []);
  return (
    <div
      id="leader"
      className="bg-[#0B0A0A] relative flex flex-col items-center pb-[4rem]"
    >
      <div className="flex flex-col items-center w-[95%]">
        <div className="flex items-center gap-[0.62rem] mb-[2.5rem]">
          <div
            className="w-[1.9375rem] h-[1.9375rem] rounded-full flex items-center justify-center"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.10)" }}
          >
            <div
              className={`w-[0.9375rem] h-[1rem] rounded-full ${
                dataCurr?.status === "closed" ? "bg-red-500" :"bg-[#02FA02]"
              }`}
            ></div>
          </div>
          <p className="text-white font-plus text-[1.25rem] font-medium">
            {dataCurr?.status === "closed" ? "Competition Ended" : "Live"}
          </p>
        </div>
        <div
          className="lg:w-[90%] w-[100%] max-h-[56.5rem] backdrop-blur-[50px] lg:p-[1.37rem] p-[0.685rem] rounded-[1.25rem]"
          style={{ backgroundColor: " rgba(255, 255, 255, 0.20)" }}
        >
          <LeadershipBoardTable column={headers} data={data} />
        </div>
      </div>
    </div>
  );
}

export default LeadershipBoard;
