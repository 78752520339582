import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function TermsConditions() {
    return (
        <div className='bg-black'>
            <Navbar />
            <div>
                <h1 className='text-white lg:text-[4.6875rem] text-[3rem] text-center font-bold my-[80px]'>Terms and Condititons</h1>
                <p className='text-[#a0a0a0] lg:w-[50%] w-[95%] text-center mx-auto mt-[10px] mb-[80px]'>Welcome to competition.gohamma.com (the "Website"). These Terms and Conditions ("Terms") govern your access to and use of the Website. You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>
                <div className='text-white font-plus lg:px-[274px] px-[1rem] flex flex-col gap-16 mb-[40px]'>
                    <div>
                        <h1 className='mb-5'>Eligibility:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>By using the Website, you affirm that you are at least 18 years old. If you are under 18 years old, you affirm that you have obtained consent from a parent or legal guardian to use the Website.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>User Accounts:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>In order to access certain features of the Website, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>User Conduct:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>You agree to use the Website in compliance with all applicable laws, regulations, and these Terms.</li>
                            <li>
                                <p>You agree not to:</p>
                                <ul className='list-disc pl-[50px] flex flex-col gap-5 text-[#a0a0a0]'>
                                    <li>Use the Website for any unlawful or unauthorized purpose.</li>
                                    <li>Impersonate any person or entity, or falsely state or misrepresent your affiliation with any person or entity.</li>
                                    <li>Interfere with or disrupt the operation of the Website or servers or networks connected to the Website.</li>
                                    <li>Attempt to gain unauthorized access to any portion of the Website or any other systems or networks connected to the Website.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Intellectual Property:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>All content and materials on the Website, including text, graphics, logos, images, and software, are owned or licensed by us and are protected by copyright, trademark, and other intellectual property laws.</li>
                            <li>You may not reproduce, distribute, modify, or create derivative works of any content from the Website without our prior written consent.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Competitions:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>Participation in competitions hosted on the Website is subject to additional terms and conditions, which will be provided to you at the time of registration or participation.</li>
                            <li>We reserve the right to modify, cancel, or suspend any competition at any time without prior notice.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Privacy:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>Your use of the Website is subject to our Privacy Policy, which explains how we collect, use, and disclose your personal information. By using the Website, you consent to our collection and use of your information as described in the Privacy Policy.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Disclaimer of Warranties:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>The Website is provided on an "as is" and "as available" basis without warranties of any kind, either express or implied. We do not warrant that the Website will be uninterrupted, secure, or error-free.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Limitation of Liability:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Governing Law:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>These Terms shall be governed by and defined following the laws of Nigeria. Asokoro Technologies Limited and yourself irrevocably consent that the courts of Nigeria shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Changes to Terms:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>We reserve the right to modify or revise these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Website. Your continued use of the Website after the posting of the revised Terms constitutes your acceptance of the changes.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Term and Termination:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</li>
                            <li>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</li>
                        </ul>
                    </div>
                    <div>
                        <h1 className='mb-5'>Contact Us:</h1>
                        <ul className='list-disc pl-[50px] flex flex-col gap-10 text-[#a0a0a0]'>
                            <li>If you have any questions or concerns about these Terms, please contact us at hello@gohamma.com.</li>
                        </ul>
                    </div>
                </div>
                <p className='text-[#a0a0a0] lg:w-[50%] w-[95%] text-center mx-auto my-[50px]'>By accessing or using the Website, you agree to abide by these Terms and any additional terms and conditions provided to you in connection with specific services or features of the Website.</p>
            </div>
            <Footer />
        </div>
    )
}

export default TermsConditions