import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function Policy() {
    return (
        <div className='bg-black'>
            <Navbar />
            <div>
                <h1 className='text-white text-[4.6875rem] text-center font-bold mt-[80px]'>Privacy Policy</h1>
                <p className='text-[#a0a0a0] lg:w-[50%] w-[95%] text-center mx-auto mt-[10px] mb-[80px]'>Thank you for visiting competition.gohamma.com (the "Website"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our Website.</p>
                <div className='text-white font-plus lg:px-[171px] px-[1rem] flex flex-col gap-16 mb-[40px]'>
                    <div className='lg:flex justify-between '>
                        <h1 className='mb-5'>Information We Collect:</h1>
                        <ul className='text-[#a0a0a0] lg:w-[50%] w-[95%] pl-5 lg:p-0 list-disc flex flex-col gap-5'>
                            <li>Personal Information: We may collect personal information such as your name, email address, and other contact details when you register an account, participate in competitions, or communicate with us.</li>
                            <li>Usage Information: We automatically collect certain information about your device and how you interact with our Website, such as your IP address, browser type, pages visited, and timestamps.</li>
                        </ul>
                    </div>
                    <div className='lg:flex justify-between '>
                        <h1 className='mb-5'>Use of Information:</h1>
                        <ul className='text-[#a0a0a0] lg:w-[50%] w-[95%] pl-5 lg:p-0 list-disc flex flex-col gap-5'>
                            <li>We may use your personal information to provide and improve our services, administer competitions, communicate with you, and personalize your experience on the Website.</li>
                            <li>We may use usage information to analyze trends, troubleshoot issues, and optimize the performance of our Website.</li>
                        </ul>
                    </div>
                    <div className='lg:flex justify-between '>
                        <h1 className='mb-5'>Sharing of Information:</h1>
                        <ul className='text-[#a0a0a0] lg:w-[50%] w-[95%] pl-5 lg:p-0 list-disc flex flex-col gap-5'>
                            <li>We may share your personal information with third-party service providers who assist us in operating our Website, conducting competitions, or providing services to you.</li>
                            <li>We may also share your information when required by law or to protect our rights and interests.</li>
                        </ul>
                    </div>
                    <div className='lg:flex justify-between '>
                        <h1 className='mb-5'>Cookies and Tracking Technologies:</h1>
                        <ul className='text-[#a0a0a0] lg:w-[50%] w-[95%] pl-5 lg:p-0 list-disc flex flex-col gap-5'>
                            <li>We use cookies and similar tracking technologies to enhance your experience on the Website and collect usage information.</li>
                            <li>You can manage your cookie preferences through your browser settings, but please note that disabling cookies may affect certain features of the Website.</li>
                        </ul>
                    </div>
                    <div className='lg:flex justify-between '>
                        <h1 className='mb-5'>Data Security:</h1>
                        <ul className='text-[#a0a0a0] lg:w-[50%] w-[95%] pl-5 lg:p-0 list-disc flex flex-col gap-5'>
                            <li>We implement appropriate security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</li>
                            <li>However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</li>
                        </ul>
                    </div>
                    <div className='lg:flex justify-between '>
                        <h1 className='mb-5'>Third-Party Links:</h1>
                        <ul className='text-[#a0a0a0] lg:w-[50%] w-[95%] pl-5 lg:p-0 list-disc flex flex-col gap-5'>
                            <li>Our Website may contain links to third-party websites or services that are not operated by us. We are not responsible for the privacy practices or content of these third-party sites.</li>
                        </ul>
                    </div>
                    <div className='lg:flex justify-between '>
                        <h1 className='mb-5'>Children's Privacy:</h1>
                        <ul className='text-[#a0a0a0] lg:w-[50%] w-[95%] pl-5 lg:p-0 list-disc flex flex-col gap-5'>
                            <li>Our Website is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If you believe that we have inadvertently collected information from a child under 18, please contact us immediately.</li>
                        </ul>
                    </div>
                    <div className='lg:flex justify-between '>
                        <h1 className='mb-5'>Changes to this Privacy Policy:</h1>
                        <ul className='text-[#a0a0a0] lg:w-[50%] w-[95%] pl-5 lg:p-0 list-disc flex flex-col gap-5'>
                            <li>We may update this Privacy Policy from time to time. Any changes will be reflected on this page with a revised effective date. We encourage you to review this Privacy Policy periodically for any updates.</li>
                        </ul>
                    </div>
                    <div className='lg:flex justify-between '>
                        <h1 className='mb-5'>Contact Us:</h1>
                        <ul className='text-[#a0a0a0] lg:w-[50%] w-[95%] pl-5 lg:p-0 list-disc flex flex-col gap-5'>
                            <li>If you have any questions or concerns about this Privacy Policy or our practices regarding your personal information, please contact us at hello@gohamma.com.</li>
                        </ul>
                    </div>
                </div>
                <p className='text-[#a0a0a0] lg:w-[50%] w-[95%] text-center mx-auto my-[50px]'>By using our Website, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Policy