import React, { useEffect, useState } from 'react'
import Bgcolora from "../assets/Group 273.svg"
import Top3ClanTable from './tables/Top3ClanTable';
import Top3TradersTable from './tables/Top3TradersTable';
import axios from 'axios';

const headers = [
  {
    key: "id",
    label: "",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "total-profit",
    label: "Total Profit",
  },
];



function Top3() {
  const [top3clans, setTop3Clans] = useState([])
  const [topTraders, setTopTraders] = useState([])
  const [currentCompetiton, setCurrentCompetition] = useState({})
  useEffect(() => {
    axios.get("https://api-hamma-f0bcaabf77ea.herokuapp.com/leaderboard/top-clans").then(res => {
      console.log(res)
      setTop3Clans(res.data.slice(0, 3))
      // setData(res.data)
    })
    axios.get("https://api-hamma-f0bcaabf77ea.herokuapp.com/leaderboard/currentcompetition").then((res) => {
      console.log(res)
      setCurrentCompetition(res.data)
      // localStorage.setItem("comp_id", res.data.id)
    });
  }, [])

  useEffect(() => {
    if(currentCompetiton.status === "current"){
      axios.get(`https://api-hamma-f0bcaabf77ea.herokuapp.com/leaderboard/leaderboard`).then(res => {
      console.log(res.data)
      setTopTraders(res.data.slice(0, 3))
      // setData(res.data)
    })
    }else{
      axios.get("https://api-hamma-f0bcaabf77ea.herokuapp.com/leaderboard/top-traders").then(res => {
        console.log(res)
        setTopTraders(res.data)
        // setData(res.data)
      })
    }
  }, [currentCompetiton.status])

  return (
    <div className='bg-[#0B0A0A] relative flex flex-col items-center'>
      <div className='flex flex-col items-center lg:pt-[4.6rem] pb-[8rem]'>
        <h1 className='lg:w-[29.5625rem] text-center font-plus lg:text-[3.125rem] text-[2rem] font-extrabold text-white lg:mb-[5.12rem] mb-[1rem]'>Top 3 Clans & Top 3 Traders</h1>
        <div className='relative w-[95%] flex flex-col lg:flex-row items-center gap-[3.12rem]'>
          <img src={Bgcolora} alt="" className='absolute lg:left-[14rem] -z-9 lg:bottom-0 bottom-[10rem] lg:w-[28.82413rem]' />
          <div className='lg:w-[31.5rem] w-[100%] max-h-[19rem] rounded-[1.25rem] backdrop-blur-[50px]' style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
            <h1 className='text-white text-center text-[1.5rem] font-plus font-extrabold my-[1.19rem]'>Top 3 Clan</h1>
            {top3clans.length > 0 && <Top3ClanTable column={headers} data={top3clans} />}
            {top3clans.length < 1 && <img src='https://res.cloudinary.com/ddlupbcws/image/upload/q_100/v1712668935/Frame_37101_znzdi2.webp' alt='' className='w-[155px] h-[178px] m-auto' />}
          </div>
          <div className='lg:w-[31.5rem] w-[100%] max-h-[19rem] rounded-[1.25rem] backdrop-blur-[50px]' style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
            <h1 className='text-white text-center text-[1.5rem] font-plus font-extrabold my-[1.19rem]'>Top 3 Traders</h1>
            {topTraders.length > 0 && <Top3TradersTable column={headers} data={topTraders} />}
            {topTraders.length < 1 && <div><img src='https://res.cloudinary.com/ddlupbcws/image/upload/q_100/v1712756120/E-library_cby0bj.webp' alt='' className='w-[114px] h-[94px] m-auto' />
              <p className='text-center font-plus text-white font-medium text-[14px] mt-[24px]'>Nothing to see here yet</p>
              <p className='text-center font-plus text-[#ffffffa7] font-medium text-[12px] mt-[5px] mb-[20px]'>Join a competition to top the list</p>
            </div>}
          </div>
        </div>

      </div>
    </div>
  )
}

export default Top3