import React from 'react'
import { Disclosure } from '@headlessui/react'
import Bgcolora from "../assets/Group 379.svg"
import Bgcolor from "../assets/Group 380.svg"

const faq = [
    {
        id: 1,
        main: "Participation and Eligibility",
        ques: [
            {
                id: 1,
                quesh: "What is Hamma?",
                answer: "Hamma is the Next-Gen Trading Platform for Nigerian Stocks. Hamma isn't just another online trading platform — it's designed for Gen Z and Millennials who want to actually make money actively trading the Nigerian stock market."
            },
            {
                id: 2,
                quesh: "What is Hamma Trading Competition?",
                answer: "This competition provides participants with a distinct opportunity to demonstrate their expertise and win cash prizes."
            },
            {
                id: 3,
                quesh: "Who can participate in the trading competition?",
                answer: "The trading competition is open to traders of all experience levels, from beginners to experts."
            },
            {
                id: 4,
                quesh: "How do I participate?",
                answer: "To participate, create an account on the Hamma platform and navigate to the trading competition section. Follow the instructions and start trading."
            },
            {
                id: 5,
                quesh: "Are there any entry fees for the trading competition?",
                answer: "No, participation in the trading competition is absolutely free. Simply signup and get started."
            },
        ]
    },
    {
        id: 2,
        main: "Competition Rules",
        ques: [
            {
                id: 1,
                quesh: "Is there a limit to the number of trades I can make during the competition?",
                answer: "There are no restrictions on the number of trades you can make during the competititon."
            },
            {
                id: 2,
                quesh: "What is the minimum trading days?",
                answer: "For the competition period, you are required to engage in trading activities (that is, closing positions) for a minimum of 10 trading days."
            },
            {
                id: 3,
                quesh: "Minimum trade duration?",
                answer: "2 minutes. Scalping is permitted, but trading activities involving several positions held for less than 2 minutes are not allowed."
            },
            {
                id: 4,
                quesh: "Can I withdraw my competition winnings immediately after the competition ends?",
                answer: "As this competition is conducted on a demo account, the winnings earned during the competition are virtual and cannot be withdrawn as real money. Now, the top trader wins a prize of N500,000. Additionally, members of the top Clan get an equal share of N500,000, the Clan prize."
            },

        ]
    },
    {
        id: 3,
        main: "Clan",
        ques: [
            {
                id: 1,
                quesh: "What is a Hamma Clan?",
                answer: "Hamma Clan is like a team of traders who share the same ideas about trading or who are friends and learn together. They can be people you know, like your friends, mentors, students, or others from your trading group or academy."
            },
            {
                id: 2,
                quesh: "What are the benefits of creating a Clan?",
                answer: "When you create a clan and invite your fellow traders to join, you create an opportunity for them to earn even if they don't end up as the top Trader. You also get to learn, teach and connect with other Traders."
            },
            {
                id: 3,
                quesh: "What are the benefits of joining a Clan?",
                answer: "When you join a Clan and the clan becomes a top Clan, you earn even if you don't end up as the top Trader. You also get to learn, teach and connect with other traders."
            },
            {
                id: 4,
                quesh: "Who can create a Clan?",
                answer: "Any registered member can create a clan."
            },
            {
                id: 5,
                quesh: "How do I join a Clan?",
                answer: "Any registered member can join a clan."
            },
            {
                id: 6,
                quesh: "What are the minimum and maximum membership limits for a Clan?",
                answer: "Clan needs at least 3 traders to start. There's no limit on how many can join."
            },
            {
                id: 7,
                quesh: "How do I request for a Clan Payout",
                answer: "You request for Payout from your Hamma trading competition dashboard."
            },
        ]
    },
    {
        id: 4,
        main: "Others",
        ques: [
            {
                id: 1,
                quesh: "Is there a limit to the number of participants in the trading competition?",
                answer: "There is no limit to the number of participants in the trading competition. Invite your friends, colleagues, and fellow traders to join the competition and compete against each other for glory and prizes."
            },
            {
                id: 2,
                quesh: "What happens if I encounter technical issues during the competition?",
                answer: "In the event of technical issues or platform downtime, our support team is available to assist you promptly. Simply reach out to our customer support team via email."
            },
            {
                id: 3,
                quesh: "I want to partner with you. How do I reach out?",
                answer: "We'll love to hear from you. Please email hello@gohamma.com"
            },

        ]
    },
]

function Faq() {
    // const [qa, setQa] = useState({
    //     q: "What is FT9ja Trading Competition?",
    //     a: "Lorem ipsum dolor sit amet consectetur. Pretium faucibus orci lectus ut donec. Tellus egestas imperdiet dapibus"
    // })
    return (
        <div id='faq' className='bg-[#201D1D] relative '>
            <img src={Bgcolora} alt='' className='absolute left-0 top-10 h-[23.78425rem]' />
            <img src={Bgcolor} alt='' className='absolute right-0 bottom-0 h-[23.78425rem]' />
            <div className='pt-[3.12rem] pb-[8rem] flex flex-col items-center w-[100%]'>
                <h1 className='text-center font-plus lg:text-[3.125rem] text-[2rem] font-semibold text-[#00C500]'>FAQ</h1>
                <p className='font-plus text-white font-normal text-[1rem] text-center mt-[0.62rem]'>Have questions? We’re here to help</p>
                {/* <div className='flex items-center gap-[0.62rem] rounded-[0.625rem] py-[1.12rem] pl-[1.25rem] lg:w-[31rem] w-[80%] mt-[2.5rem]' style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z" fill="white" />
                        <path d="M21.9999 22.7499C21.8099 22.7499 21.6199 22.6799 21.4699 22.5299L19.4699 20.5299C19.1799 20.2399 19.1799 19.7599 19.4699 19.4699C19.7599 19.1799 20.2399 19.1799 20.5299 19.4699L22.5299 21.4699C22.8199 21.7599 22.8199 22.2399 22.5299 22.5299C22.3799 22.6799 22.1899 22.7499 21.9999 22.7499Z" fill="white" />
                    </svg>
                    <input type="text" placeholder='Search' className='bg-transparent outline-none placeholder:text-white placeholder:font-plus w-[90%] text-white font-plus' />
                </div> */}
                {/* <div className='flex lg:hidden lg:flex-row lg:gap-[17.12rem] gap-[3rem] mt-[7.75rem]'>
                    <div className='flex flex-col gap-[3.13rem] ml-[1rem] lg:ml-0'>
                        {faq.map(fa => (
                            <div key={fa.id} className='flex flex-col gap-[2.19rem]'>
                                <p className='font-plus text-[1.125rem] font-extrabold text-[#00C500]'>{fa.main}</p>
                                <div className='flex flex-col gap-[1.25rem]'>
                                    {fa.ques.map(que => (
                                        <p onClick={() => setQa({ q: que.quesh, a: que.answer })} className='cursor-pointer font-plus text-[1rem] font-normal text-white' key={que.id}>{que.quesh}</p>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='backdrop-blur-[50px] rounded-[0.625rem] lg:w-[35.125rem] w-[95%] mx-auto lg:mx-0 lg:h-[22.625rem] lg:p-[3rem] p-[1rem]' style={{ backgroundColor: "rgba(255, 255, 255, 0.10)" }}>
                        <p className='font-plus text-white text-[1.5rem] font-semibold mb-[1.87rem]'>{qa.q}</p>
                        <p className='font-plus text-[#D2D2D2] text-[1rem] font-normal'>{qa.a}</p>
                    </div>
                </div> */}
                <div className='mt-5 lg:w-[60%] w-[100%] px-[1rem] z-10'>
                    {faq.map(fa => (
                        <div key={fa.id} className='flex flex-col gap-5'>
                            <p className='font-plus text-[1.125rem] font-extrabold text-[#00C500] mt-14'>{fa.main}</p>
                            <div className='flex flex-col gap-[1.25rem]'>
                                {fa.ques.map(que => (
                                    <Disclosure as={"div"} className='lg:border-b lg:border-b-white cursor-pointer w-[100%] font-plus text-[1rem] font-normal text-white' key={que.id}>
                                        {({ open }) => (
                                            <>

                                                <Disclosure.Button className="w-[100%] flex justify-between text-left lg:py-[2rem] py-[0.5rem]">
                                                    {que.quesh}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={`${open && "rotate-180"}`}>
                                                        <path d="M13 10L8 5L3 10" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="text-gray-400 w-[100%] my-8">
                                                    {que.answer}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Faq
