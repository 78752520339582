import React from 'react';
import { SyncLoader } from 'react-spinners';

export default function SyncLoading({ size,color }) {
  return (
        <div className="loadingBoxComp p-1" >
                <SyncLoader color={color} size={size} speedMultiplier={.55} />
        </div>
  );
}


SyncLoading.defaultProps={
        size:7,
        color: "#ABABAB",
}