import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef } from 'react'
import { Link } from 'react-router-dom'

function MobileMenu(props) {
    let completeButtonRef = useRef(null)

    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog
                open={props.isOpen}
                initialFocus={completeButtonRef}
                onClose={() => props.setIsOpen(false)}
                className="relative z-50"
                as="div"
            >
                {/* The backdrop, rendered as a fixed sibling to the panel container */}
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                {/* Full-screen container to center the panel */}
                <div className="fixed inset-0 flex items-center justify-center">
                    {/* The actual dialog panel  */}
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-out duration-300 transform"
                        enterFrom="opacity-0 translate-x-full"
                        enterTo="opacity-100 translate-x-0"
                        leave="transition ease-in duration-200 transform"
                        leaveFrom="opacity-100 translate-x-0"
                        leaveTo="opacity-0 translate-x-full"
                    >
                        <Dialog.Panel className="mx-auto w-[100%] pb-10 absolute top-[4.6rem] backdrop-blur-[50px]" style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
                            <div className="h-[100%]">
                                <div className="flex flex-col justify-between pt-10 ml-[1rem]">
                                    <p
                                        ref={completeButtonRef}
                                        onClick={() => props.setIsOpen(false)}
                                        className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
                                    >
                                        close
                                    </p>
                                    <div className="grid gap-y-[2rem]">
                                        <div className="flex items-center gap-[0.56rem]">
                                            {/* <Image src={Plan} alt="" priority /> */}
                                            <a onClick={() => props.setIsOpen(false)} href='#hiw' className="font-plus text-[1rem] text-white">
                                                How it works
                                            </a>
                                        </div>
                                        <div className="flex items-center gap-[0.56rem]">
                                            <a onClick={() => props.setIsOpen(false)} href='#leader' className="font-plus text-[1rem] text-white whitespace-nowrap">
                                                Leaderboard
                                            </a>
                                        </div>
                                        <div className="flex items-center gap-[0.56rem]">
                                            <a onClick={() => props.setIsOpen(false)} href='#faq' className="font-plus text-[1rem] text-white">
                                                FAQ
                                            </a>
                                        </div>
                                        <div className="flex items-center gap-[0.56rem]">
                                            <a rel='noreferrer' target='_blank' href='https://hamma.notion.site/Wiki-9a5606049202424baffacfb33b8b54b0' className="font-plus text-[1rem] text-white">
                                                Guide
                                            </a>
                                        </div>
                                        <div className="flex items-center gap-[0.56rem]">
                                            <a rel='noreferrer' target='_blank' href='/rules' className="font-plus text-[1rem] text-white">
                                                Rules
                                            </a>
                                        </div>
                                        {!localStorage.getItem("access_token") && <Link to="/login">
                                            <div className="flex items-center border border-white w-[95%] py-[0.5rem] rounded-lg pl-[0.5rem] gap-[0.56rem]">
                                                {/* <Image src={Free} alt="" priority /> */}
                                                <p className="font-plus text-[1rem] text-white whitespace-nowrap">
                                                    Sign in
                                                </p>
                                            </div>
                                        </Link>}
                                        {localStorage.getItem("access_token") && <div className="flex items-center border border-white w-[95%] py-[0.5rem] rounded-lg pl-[0.5rem] gap-[0.56rem]">
                                            {/* <Image src={Free} alt="" priority /> */}
                                            <p className="font-plus text-[1rem] text-white whitespace-nowrap">
                                                Log out
                                            </p>
                                        </div>}
                                        {!localStorage.getItem("access_token") && <Link to="/register">
                                            <div className="flex items-center gap-[0.56rem] bg-white w-[95%] rounded-lg py-[0.5rem] pl-[0.5rem]">
                                                {/* <Image src={Free} alt="" priority /> */}
                                                <p className="font-plus text-[1rem] font-semibold text-black whitespace-nowrap">
                                                    Sign up
                                                </p>
                                            </div>
                                        </Link>}
                                        {localStorage.getItem("access_token") && <Link to={`https://leaderboard.gohamma.com/?token=${localStorage.getItem("access_token")}&refresh_token=${localStorage.getItem("refresh_token")}`}>
                                            <div className="flex items-center gap-[0.56rem] bg-white w-[95%] rounded-lg py-[0.5rem] pl-[0.5rem]">
                                                {/* <Image src={Free} alt="" priority /> */}
                                                <p className="font-plus text-[1rem] font-semibold text-black whitespace-nowrap">
                                                    Dashboard
                                                </p>
                                            </div>
                                        </Link>}
                                    </div>

                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default MobileMenu